import React from 'react'
import {FormattedMessage} from "gatsby-plugin-intl"
import { Link } from 'gatsby'
import style from './footer.module.css'


const Footer = ({ siteTitle }) => {

  return(
  <div className= {style.footer}>
    <div className='row'>
      <div className='col-4'>
      
        <Link to='/about/'> <strong><FormattedMessage id= "menu.Kontakt"/></strong> </Link>
        <br/>
        <Link to='/Impressum/datenschutzbestimmungen/'><strong><FormattedMessage id="menu.Datenschutz"/></strong></Link>
        <br/>
        <Link to='/about/'> <strong><FormattedMessage id="menu.Impressum"/></strong></Link>
     </div>
    </div>

  </div>
  )
}

export default Footer