/* Menue 
========
Stand: 31.10.31/ f
Funktion:
Obere Menuezeile / 1 ste  Zeile 
Erweitert mit
- Hamburger Menue


*/
import React, {Component} from 'react'
import {FormattedMessage} from "gatsby-plugin-intl"
import Link from 'gatsby-link'
import style from './menu.module.css'
/* Logo import */
import logo from "../images/Ficotex_Logo.jpg"
import hamburgerbutton from "../images/hamburger.jpg"
import crossbutton from "../images/cross.jpg"

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {showModal: false};
    this.handleClick = this.handleClick.bind(this);
  }

// function to handle the click
 handleClick() {
  this.setState(prevState => ({
    showModal: !prevState.showModal
  }));
}

render(){

  const hamburger = (
    <div> 
      <img style={{marginBottom : '0' , width: '25px'}} src={hamburgerbutton} alt='menu open'/>
    </div>
  );
  const cross = (
    <div>
            <img style={{marginBottom : '0' , width: '25px'}} src={crossbutton} alt='menu close'/>

    </div>
  );

  const dropdown = (
    <div>
      <div className="modal-content">
                <ul className={style.submenu}>
                {/* unterhalb des Bildes die einzelnen Blöcke   */}
                  <li><Link to='/'> <h4>Home</h4> </Link> </li>
                  <li><h4 class='nonlink'><FormattedMessage id="menu.Leistung" /> </h4> 
                  <ul className={style.submenu}>
                  {/* unterhalb des Bildes die einzelnen Blöcke  */ }
                    <li><Link to='/Drucker/printer/'> <h4><FormattedMessage id="menu.3DDruck"/></h4> </Link> </li>
                    <li><Link to='/Dienstleistungen/dienstleistungen/'> <h4><FormattedMessage id="menu.Dienstleistungen"/></h4> </Link> </li>
                   
                  </ul>
                  </li>
                  <li>
                    <h4 className='nonlink'><FormattedMessage id="menu.Uebersicht"/></h4>
                    <ul className={style.submenu}>
                    {/* unterhalb des Bildes die einzelnen Blöcke  */ }
                    <li><Link to='/Materialien/Materialien/'> <h4><FormattedMessage id="menu.Uebersicht"/></h4> </Link> </li>
                    
                  </ul>
                  </li>
                  <li><Link to='/about/'>                   <h4><FormattedMessage id="menu.Kontakt"/></h4> </Link> </li>
                  <li><Link to='/Veranstaltungen/Va-keine/'> <h4><FormattedMessage id="menu.Veranstaltungen"/></h4> </Link> </li>
                </ul>
              </div>

    </div>
    
  );
  
 
  return(
  
  <div className={style.menu}>
    
    <div  className='row'
    style={{
      maxWidth: '100%',
    }}
    >
      <div className='col-2'
      style={{padding:'0'}}>
        
        <div className='col-s-10'>
          <div className={style.logo}  >
            < Link to='/'> 
              {/* Logo */}
              <img style={{marginBottom : '20'}} src={logo} alt='bild'/>
            </Link>
          </div>
        </div>

      {/* Menueleiste : für smartphone --> noch nicht fertig*/}
        <div className='hide-l hide-m col-s-2'>
          {/* <Modal/> */}
          <div className='button' id='fixedbutton' onClick={this.handleClick}>
            {this.state.showModal ? cross : hamburger}
          </div>
          {this.state.showModal ? dropdown : ''}
        </div>
      </div>

      {/* Menu für big screen and tablet  */}
      <div className='col-10 hide-s'
      style={{paddingBottom:'0'}}>
        <ul className={style.menuBar}> 
            <li><Link to='/Veranstaltungen/Va-keine/'> <h4><FormattedMessage id="menu.Veranstaltungen"/></h4> </Link></li>

            <li><Link to='/about/'> <h4><FormattedMessage id="menu.Kontakt"/></h4> </Link></li>
            <li>
              <div className="dropdown">
               <h4><FormattedMessage id="menu.Materialien"/></h4> 
                <div className="dropdown-content">
                  <ul className={style.submenu}>
                  {/* unterhalb des Bildes die einzelnen Blöcke  */ }
                    <li><Link to='/Materialien/Materialien/'> <h4><FormattedMessage id="menu.Uebersicht"/></h4> </Link> </li>
                    
                  </ul>
                </div>
              </div>
            </li>

            <li>
              <div className="dropdown">
               <h4><FormattedMessage id="menu.Leistung"/></h4> 
                <div className="dropdown-content">
                  <ul className={style.submenu}>
                  {/* unterhalb des Bildes die einzelnen Blöcke  */ }
                    <li><Link to='/Drucker/printer/'> <h4><FormattedMessage id="menu.3DDruck"/></h4> </Link> </li>
                    <li><Link to='/Dienstleistungen/dienstleistungen/'> <h4><FormattedMessage id="menu.Dienstleistungen"/></h4> </Link> </li>
                   
                  </ul>
                </div>
              </div>
            </li>
            <li><Link to='/'> <h4>Home</h4> </Link></li>

        </ul>
      </div>

    
    </div>

  
  </div>  
  )
  }}


// Menu.propTypes = {
//    siteTitle: PropTypes.string,
// }
  
// Menu.defaultProps = {
//   siteTitle: ``,
// }



export default Menu;