import React from "react"
import {IntlContextConsumer, changeLocale} from "gatsby-plugin-intl"

const languageName = {
    en: "en",
    de: "de",
}

const Language = () => {
    return (
        <div 
        style={{
            textAlign: `right`,
            marginRight: 12
        }}
        >
            <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
                languages.map(language => (
                    <button 
                    key={language}
                    onClick={() => changeLocale(language)}
                    style={{
                        color: currentLocale === language ? `rgb(72, 105, 122)` : `hsla(0, 0%, 0%, 0.8)`,
                        marginBottom: 1,
                        textDecoration: currentLocale === language ? `underline` : `none`,
                        cursor: `pointer`,
                        fontSize: 15,
                        border: 0,
                        backgroundColor: `transparent`
                        }}
                    >
                    {languageName[language]}
                    </button>
                ))
            }
            </IntlContextConsumer>
        </div>
    )
}

export default Language